@use 'spotlab' as *;

.overlay-panel-toggle {
  background-color: #fff;
  margin: 0px;
  padding: auto;
  width: 48px;
  text-align: center;
  height: 36px;
  display: inline-block;
  &.active {
    z-index: 1;
    box-shadow: $dropdown-shadow;
  }
}
.overlay-panel {
  z-index: 101;
  position: absolute;
  background-color: #fff;
  padding: 24px 16px;
  border-radius: 0px 0px 2px 2px;
  box-shadow: $dropdown-shadow;
  width: auto;
}
