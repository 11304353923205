@use 'spotlab' as *;

mat-toolbar.mat-toolbar {
  padding: 0px 8px;
}

mat-toolbar.my-nav,
.my-nav {
  height: $navbar-height;
  background-color: white;
  a {
    color: map-get($colors, text);
  }
  box-shadow: 0px 0px 20px rgb(207, 207, 207);
  padding: 0 5%;
  flex: 1;
  margin: 0px;
  z-index: 100;

  .nav-link {
    margin: 0px;
    padding: auto;
    line-height: 54px;
  }

  .active-navlink {
    background-color: map-get($colors, main);
    a {
      color: white;
    }
    border: none;
  }
}
