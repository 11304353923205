nav[mat-tab-nav-bar] {
  .mat-tab-link-container {
    .mat-tab-list {
      max-width: 100%;
      .mat-tab-links {
        a {
          flex: 1 0 auto;
          padding: 10px 0px;
          height: auto;
          opacity: 1;
          &:hover {
            text-decoration: none;
          }
        }
      }
      mat-ink-bar {
        height: 4px;
      }
    }
  }
  &.space-evenly {
    .mat-tab-link-container .mat-tab-list .mat-tab-links a[mat-tab-link] {
      flex: 1 1 100%;
    }
  }
}
