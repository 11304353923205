mat-radio-button {
  &.no-margin {
    label.mat-radio-label {
      margin-bottom: 0px;
    }
  }
  &.text-ellipsis {
    label.mat-radio-label .mat-radio-label-content {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.mat-radio-label {
  width: 100%;
}
.mat-radio-label-content {
  flex-grow: 1;
  width: 100%;
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
