.cursor-pointer {
  cursor: pointer;
}

.cursor-crosshair {
  cursor: crosshair;
}

.cursor-move {
  cursor: move;
}

.cursor-poi {
  cursor: url("../../../assets/src/assets/icons/focus.svg") 8 8, pointer;
}
