@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes pulse {
  80% {
    opacity: 0.5;
    // filter: alpha(opacity=50);
  }
}
@-webkit-keyframes pulse {
  80% {
    opacity: 0.5;
    // filter: alpha(opacity=50);
  }
}
@keyframes pulse {
  80% {
    opacity: 0.5;
    // filter: alpha(opacity=50);
  }
}

@-moz-keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
