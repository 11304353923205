@use 'placeholders' as *;

.main-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 20px;
  max-width: 1216px;
  flex-grow: 1;
  min-height: 100%;
  height: auto;

  .title {
    // padding: 0px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    text-transform: uppercase;

    padding: 0px 10px;
    h1 {
      flex-grow: 1;
      margin-bottom: 0px;
    }
    .btn {
      text-transform: uppercase;
      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }
}

.login-form {
  width: 300px;
  max-width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  background-color: #fff;
  padding: 8px;
}
