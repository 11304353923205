@use 'spotlab' as *;
mat-accordion {
  &.transparent {
    mat-expansion-panel {
      background-color: transparent;
      mat-expansion-panel-header {
        background-color: white;
        &:hover:not([aria-disabled='true']) {
          background-color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  &.spotlab {
    box-shadow: $card-shadow;
    mat-expansion-panel {
      background-color: transparent;
      mat-expansion-panel-header {
        margin-top: 2px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        height: 56px;
        &.mat-expanded {
          background-color: white;
        }
        &:not(.mat-expanded) {
          *,
          mat-panel-title,
          mat-panel-description,
          .mat-expansion-indicator:after {
            color: map-get($colors, 'white');
          }
          background-color: map-get($colors, 'main');
        }
        &:hover {
          &:not(.mat-expanded) {
            *,
            mat-panel-title,
            mat-panel-description,
            .mat-expansion-indicator:after {
              color: map-get($colors, 'white');
            }
          }
          &.mat-expanded {
            background-color: white;
          }
          &:not(.mat-expanded, [aria-disabled='true']) {
            background-color: map-get($colors, 'main-hover') !important;
          }
        }
      }
    }
  }
}
