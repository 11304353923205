// Colors
$colors: (
  main: #0099bb,
  main-secondary: #84b2bc,
  main-hover: #86dcef,
  primary: #0099bb,
  primary-secondary: #84b2bc,
  primary-hover: #86dcef,
  hover: #86dcef,
  // TODO: deprecate
  bg: #fcfdff,
  "red": #be1e2d,
  red-hover: #f04858,
  red-alert: #be1e2e8e,
  red-secondary: #be858a,
  danger: #be1e2d,
  danger-hover: #f04858,
  danger-alert: #be1e2e8e,
  danger-secondary: #be858a,
  "orange": #f7931d,
  text: #212d3e,
  "gray": #808285,
  gray-hover: #adadad,
  gray-seconday: #434444,
  "green": #009143,
  "white": #ffffff,
  disabled: #84b2bc,
  non-active: #808284,
);

$mobile-analysis-background-color: rgb(212, 212, 212);

$mat-dialog-backdrop: rgba(128, 128, 128, 0.281);

$btn-height: 48px;

$card-background-color: #fff;

$label-colors: (
  new-bg: rgba(240, 72, 88, 0.1),
  new: map-get($colors, "red"),
  review-bg: rgba(247, 147, 29, 0.1),
  review: map-get($colors, "orange"),
);

$roi-colors: (
  1: rgb(131, 134, 0),
  2: rgb(171, 30, 184),
  3: rgb(0, 50, 225),
  4: rgb(0, 150, 33),
  5: rgb(224, 119, 0),
);

$icon-size: 21px;

$main-div-bottom-padding: 30px;

$bg-color-main: map-get($colors, bg);
$bg-color-semitransparent: rgba(252, 252, 252, 0.9);
$bg-color-opaque: rgba(230, 230, 230, 1);
// Shadows
$card-shadow: 0px 8px 20px rgba(0, 0, 0, 0.03);
$card-shadow--hard: 0px 8px 15px rgba(0, 0, 0, 0.1);
$dropdown-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
$modal-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05);
// Sizes
$navbar-height: 96px;
$navbar-height-mobile: 56px;
$sidebar-width: 240px;
// Fonts
$main-font: 312;

$primary: map-get($colors, "primary");
$success: map-get($colors, "green");
$danger: map-get($colors, "danger");
