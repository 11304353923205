@use 'spotlab' as *;

mat-button-toggle-group.custom-button-toggle-group {
  border: 0px;
  padding: 4px;
  display: flex;
  flex-flow: row wrap;
}

mat-button-toggle.custom-button-toggle {
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  height: 100%;

  div.mat-button-toggle-label-content {
    line-height: 32px;
  }
  &.mat-button-toggle-checked {
    background-color: map-get($colors, 'main');
    color: white;
  }

  &.mat-button-toggle-disabled {
    opacity: 0.5;
  }
}
