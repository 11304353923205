@use 'spotlab';

$h1-font-size: 22px;
$h2-font-size: 17px;
$h3-font-size: 17px;
$h4-font-size: 17px;
$h5-font-size: 16px;
$body-font-size: 15px;
@media only screen and (min-width: 768px) {
  h1 {
    font-size: $h1-font-size;
    font-weight: bold;
    line-height: 150%;
    letter-spacing: 0.5px;
  }

  h2 {
    font-size: $h2-font-size;
    font-weight: bold;
    line-height: 150%;
    letter-spacing: 0.5px;
  }

  h3 {
    font-size: $h3-font-size;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: 0.5px;
  }

  h4 {
    font-size: $h4-font-size;
    font-weight: 600;
    line-height: 120%;
  }

  h5 {
    font-size: $h5-font-size;
    font-weight: 600;
    line-height: 120%;
  }

  body {
    font-size: $body-font-size;
    letter-spacing: 0.5px;
    font-weight: normal;
    line-height: 30px;
  }
}

$h1-font-size-sm: 20px;
$h2-font-size-sm: 16px;
$h3-font-size-sm: 16px;
$h4-font-size-sm: 16px;
$h5-font-size-sm: 14px;
$body-font-size-sm: 16px;
@media (max-width: 768px) {
  h1 {
    font-size: $h1-font-size-sm;
    font-weight: bold;
    line-height: 150%;
    letter-spacing: 0.5px;
  }

  h2 {
    font-size: $h2-font-size-sm;
    font-weight: bold;
    line-height: 150%;
    letter-spacing: 0.5px;
  }

  h3 {
    font-size: $h3-font-size-sm;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: 0.5px;
  }

  h4 {
    font-size: $h4-font-size-sm;
    font-weight: 600;
    line-height: 120%;
  }

  h5 {
    font-size: $h5-font-size-sm;
    font-weight: 600;
    line-height: 120%;
  }
}

h1,
h2,
h3,
h4,
h5 .no-mb {
  margin-bottom: 0px;
}
