.mat-form-field-hint-wrapper {
  mat-hint {
    &.mat-hint {
      flex: 1 1 50%;
    }
  }
  .mat-form-field-hint-spacer {
    display: none;
  }
}
