@use 'sass:color';
@use './spotlab' as *;
@import 'mixins';

@mixin spl-btn-base() {
  &:not([mat-icon-button]) {
    width: 280px;
    max-width: 100%;
  }
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;

  &.btn-auto {
    width: auto;
    white-space: nowrap;
  }
  border: 2px;
  border-style: solid;
  border-width: 1px;

  &:focus {
    outline: none;
  }
}

@mixin spl-btn($color: 'gray', $outline: false) {
  border-radius: 2px;
  $color-main: map-get($colors, $color);
  $color-disabled: map-get($colors, #{$color}-secondary);
  $color-hover: map-get($colors, #{$color}-hover);

  @include focus-box-shadow($color-hover);

  &:not(:hover),
  &:not(:focus) {
    @if ($outline) {
      border-color: $color-main;
      background-color: transparent;
      color: $color-main;
    } @else {
      background-color: $color-main;
      border-color: $color-main;
      color: white;
    }
  }

  &:not([disabled]):not(.disabled) {
    &:focus,
    &:hover {
      border-color: $color-hover;
      @if ($outline) {
        color: $color-hover;
      } @else {
        background-color: $color-hover;
        color: white;
      }
    }
  }

  &.disabled,
  &[disabled] {
    @if ($outline) {
      border-color: $color-disabled;
      background-color: transparent;
      color: $color-disabled;
    } @else {
      background-color: $color-disabled;
      border-color: $color-disabled;
      color: white;
    }
    opacity: 1;
    pointer-events: none;
  }

  &.btn--active {
    @if (not($outline)) {
      background: linear-gradient(to right, $color-main, $color-hover) !important;
      border: 0px solid transparent;
    }
  }

  &.btn--thin {
    max-height: 32px;
  }

  i {
    flex-basis: 32px;
  }
}

@mixin focus-box-shadow($color) {
  &:focus:focus-visible {
    box-shadow: 0 0 0 0.2rem rgba($color, 0.5);
  }
}

@mixin spl-btn-flat($color: 'gray') {
  $color-main: map-get($colors, $color);
  $color-disabled: map-get($colors, #{$color}-secondary);
  $color-hover: map-get($colors, #{$color}-hover);
  background-color: transparent;
  border: none;
  @include focus-box-shadow($color-hover);
  &:not(:hover),
  &:not(:focus) {
    color: $color-main;
  }
  &:not([disabled]):not(.disabled) {
    &:focus,
    &:hover {
      color: $color-hover;
    }
  }
  &.disabled,
  &[disabled] {
    color: $color-disabled;
  }
}

button.btn,
label.btn,
a.btn {
  @include spl-btn-base();

  &[mat-icon-button]:not([class*='btn-']) {
    @include spl-btn-flat();
    &:not(.btn-square) {
      width: auto;
      min-width: 24px;
    }
  }

  @each $color in 'primary', 'red', 'danger', 'gray' {
    &.btn-#{$color} {
      @include spl-btn($color);
    }
    &.btn-outline-#{$color} {
      @include spl-btn($color, true);
    }
    &.btn-flat-#{$color} {
      @include spl-btn-flat($color);
    }
  }

  &.btn-outline--white-bg {
    background-color: #fff !important;
  }
}
