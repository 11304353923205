@use 'sass:math';
$scroll-bar-thickness: 8px;

.custom-scrollbar {
  /* width */
  ::-webkit-scrollbar {
    width: $scroll-bar-thickness;
    height: $scroll-bar-thickness;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: math.div($scroll-bar-thickness, 2);
    // background-color: red;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(241, 241, 241);
    border-radius: math.div($scroll-bar-thickness, 2);
  }
}
