@use 'spotlab' as *;

mat-expansion-panel.mat-expansion-panel {
  &.custom-mat-expansion-panel {
    border-radius: 0px;
    box-shadow: none;

    .mat-expansion-panel-content > .mat-expansion-panel-body {
      padding: 0;
    }

    &--no-padding {
      .mat-expansion-panel-content > .mat-expansion-panel-body {
        padding: 0px;
      }
    }

    &--padding-10 {
      .mat-expansion-panel-content > .mat-expansion-panel-body {
        padding: 10px;
      }
    }

    &--semi-transparent {
      background-color: $bg-color-semitransparent;
    }
  }
}
