@use 'spotlab' as *;
snack-bar-container.mat-snack-bar-container {
  background-color: white;
  color: map-get($colors, 'text');
  border-radius: 0px;
  max-width: 90vw;
  width: auto;
  padding: auto 12px;
  box-shadow: $modal-shadow;

  &.snackbar-primary {
    background-color: map-get($colors, 'text');
    color: white;
  }
}
