@use 'spotlab' as *;

.poct-result {
  border-width: 1px;
  max-height: 32px;
  text-align: center;
  border-radius: 2px;
  padding: 0px 3px;
  margin: 0px 2px;

  &--style-1 {
    border: 1px solid;
    box-sizing: border-box;
  }

  &--style-2 {
    color: map-get($colors, 'white');
  }
}
