[mat-menu-item].mat-menu-item.flex-row {
  display: flex;
  &.align-items-center {
    align-items: center;
  }
}

.mat-menu-panel.setting-menu {
  width: 40px;
  border-radius: 0px;
  min-width: auto;
}
