mat-selection-list.mat-list-base.compact {

  mat-list-option.mat-list-item{
    height: 32px;
    .mat-list-item-content {
      padding-left: 2px;
        .mat-list-text{
          padding-left: 10px;
        }
    }
  }
}
