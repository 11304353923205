@use 'spotlab' as *;
.mat-select {
  .mat-icon {
    .right-primary-icon {
      color: map-get($colors, main);
    }
  }
  &.hideArrow .mat-select-arrow {
    border: none;
  }
}
