@use 'spotlab' as *;
mat-checkbox {
  &.no-margin {
    label {
      margin: 0px;
    }
  }

  &.blue-border {
    .mat-checkbox-frame {
      border-color: map-get($colors, 'primary');
    }
  }
}
