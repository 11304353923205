@use 'spotlab' as *;

.card {
  border: none;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: $card-shadow;

  margin: 10px;

  ul {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .button-bar {
        flex-shrink: 1;
        flex-basis: auto;
        // align-self: flex-end;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        i {
          margin: 0px 4px;
        }
      }

      label {
        margin: 0px;
        height: auto;
      }

      .btn {
        height: auto;
      }
    }
  }

  ul + ul {
    li:nth-child(1) {
      border-top: none;
    }
  }

  &.padded {
    .card-header,
    .card-body {
      padding-left: 8px;
      padding-right: 8px;
    }

    @media (min-width: 700px) {
      .card-header,
      .card-body {
        padding-left: 104px;
        padding-right: 104px;
      }
    }

    .card-header {
      h2.pl-20 {
        padding-left: 20px;
      }
    }

    &:not(.card-header) {
      .card-body {
        padding-top: 40px;
      }
    }
  }
}

.card-split {
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  &:not(&.__section) {
    flex: 0;
  }

  &__section {
    flex: 1;
    min-height: 100%;
    align-self: stretch;

    &.padded {
      padding: 20px;
    }
    &.center {
      text-align: center;
      a {
        margin: auto 0px;
        width: 100%;
      }
    }
  }
}

.card-header {
  background-color: $card-background-color;
  padding-top: 32px;
  border-bottom: none;
}

.card-footer {
  background-color: $card-background-color;
  &.no-hr {
    border-top: none;
  }
}

.card-actions {
  display: flex;
  flex-direction: horizontal;
  justify-content: space-around;

  &__action {
    margin: 4px;
    &--grow {
      flex-grow: 1;
    }
  }
}
