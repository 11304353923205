@use 'spotlab' as *;
@import '../typography';

.mat-table {
  &.responsive {
    .mat-cell,
    .mat-header-cell {
      @media (max-width: 599px) {
        padding: 0px 8px;
        overflow: hidden;
        vertical-align: middle;
        text-align: center;
      }
    }
  }
  &.spotlab-table {
    // display: table;
    // max-width: 100%;
    // width: 100%;
    // overflow-x: scroll;
    // table-layout: fixed;
    .mat-header-cell {
      .spotlab-table__header {
        font-size: 13px;
        color: map-get($colors, 'text');
        text-transform: uppercase;
        font-weight: 300;
      }
    }
    .mat-cell {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .mat-table-sticky {
      background: unset;
    }
  }
}
