%full-height {
  height: 100vh;
  @media only screen and (max-device-width : 640px) {
    // Make sure it fills the full height minus the search bar (grrr)
    height: calc(100vh - 56px);
    height: -o-calc(100vh - 56px); /* opera */
    height: -webkit-calc(100vh - 56px); /* google, safari */
    height: -moz-calc(100vh - 56px); /* firefox */
  }
}
