@mixin badge($color) {
  color: white;
  background-color: transparent;
  border: 1px solid $color;
  &:hover {
    background-color: transparent;
    color: $color;
    border: 1px solid $color;
  }
}

@mixin badge-outline($color, $withHover: false) {
  border: 1px solid $color;
  color: $color;
  background-color: transparent;
  @if ($withHover) {
    &:hover {
      background-color: $color;
      color: white;
    }
  }
}

@mixin btn-toggle($main-color) {
  border: none;
  &:not(.active) {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    color: $main-color;
    background-color: white;
  }
  &.active {
    background-color: $main-color;
    color: white;
  }
  &:hover {
    color: white;
  }
}

@mixin btn-flat($color, $color-hover) {
  color: $color;
  background-color: transparent;
  &:not(.disabled):hover {
    color: $color-hover;
  }
  &.disabled,
  &:disabled {
    color: gray;
  }
}
