mat-dialog {
}

.no-padding {
  .mat-dialog-container {
    padding: 0px;
  }
}

mat-dialog-container.mat-dialog-container {
  // margin: 12px;
  // max-height: 80vh;
}
