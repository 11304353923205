@use 'styles/spotlab';
@use 'styles/buttons';
@use 'styles/badges';
// Bootstrap so we can override styles later

@use 'styles/bootstrap_selected';

// @use './styles/remixicon.css';

@use '_angular-material-theming/telespot-mat-component-theming';
@use '_angular-material-theming/themes/mat-theme-telespot';
@use 'typography';
@use 'styles/mixins';
@use 'styles/placeholders';
@use 'styles/animations';
@use 'styles/utilities';

@use 'styles/cards';
@use 'styles/icons';
@use 'styles/layout';
@use 'styles/scrollbar';
@use 'styles/components/__components-dir';
@use 'styles/cursors';
@use 'styles/shadows';

@import 'remixicon/fonts/remixicon.css';

* {
  font-family: 'Nunito';
}

body {
  background-color: map-get(spotlab.$colors, bg);
  margin: 0px;
  padding: 0px;
}

.full-height {
  @extend %full-height;
}

a {
  text-decoration: none;
  &:hover::content {
    text-decoration: underline;
  }
}

/*  */

.breadcrumbs {
  flex: 1 0 50%;
  text-transform: none;
  max-width: 100%;
  a {
    margin: 0px 2px;
  }
  a[href]:hover {
    color: map-get(spotlab.$colors, hover);
    text-decoration: none;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  span,
  a {
    flex-shrink: 10;
    text-overflow: ellipsis;
    flex: 0 1 auto;
    white-space: nowrap;
    word-break: normal;
    overflow: hidden;
    min-width: 12px;

    &:not(:nth-child(1))::before {
      content: '/';
      padding-right: 8px;
      margin-left: 8px;
    }

    &:nth-child(1) {
      flex: 0 0 auto;
      min-width: auto;
    }

    &:last-child {
      flex: 0 0 auto;
    }
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;

  &.align-items-center {
    > i {
      // REVIEW: this is problematic
      margin-right: 8px;
    }
  }
}

.flex-align-center {
  align-items: center;
}

// Angular material styling
.full-width {
  width: 100%;
  display: block;
}

.button-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px 10px;

  &.flex-align-center {
    justify-content: center;
  }

  &.align-right {
    justify-content: flex-end;
  }

  i {
    margin: 0px 4px;
  }

  button {
    margin: 0px 4px;
  }

  @media (max-width: 420px) {
    margin-top: auto;
    align-self: flex-end;
    justify-self: flex-end;
    button {
      margin-left: 0px;
      flex-grow: 1;
      margin-top: 10px;
    }
  }
}

.progress-bar-bg {
  width: 100%;
  height: 8px;
  box-shadow: spotlab.$card-shadow;
  .progress-bar {
    background-color: map-get(spotlab.$colors, main);
    height: 100%;
    transition: width 1s;
  }
}

.list-group-flush {
  .list-group-item:last-of-type {
    border-bottom: none;
  }
}

.text {
  &-green {
    color: map-get(spotlab.$colors, 'green');
  }
  &-gray {
    color: map-get(spotlab.$colors, 'gray');
  }
  &-danger {
    color: map-get(spotlab.$colors, 'red');
  }
  &-warn {
    color: map-get(spotlab.$colors, 'orange');
  }
}

.dropdown-menu {
  border-radius: 0;
  border: none;
  box-shadow: spotlab.$card-shadow;
  .dropdown-item {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    [class^='ri-'],
    img {
      width: 28px;
      margin-right: 8px;
    }
  }
}

// Class for material dialog backdrops
.mat-dialog-backdrop {
  background-color: spotlab.$mat-dialog-backdrop;
}

.user-avatar {
  object-fit: contain;
  width: 32px;
  height: 32px;
  &:not(&--no-radius) {
    border-radius: 50%;
  }
  box-shadow: spotlab.$card-shadow;

  &:hover {
    filter: brightness(125%);
  }

  &--32 {
    width: 32px;
    height: 32px;
  }
  &--60 {
    width: 60px;
    height: 60px;
  }

  &--100 {
    width: 100px;
    height: 100px;
  }

  &--square {
    border-radius: 2px;
  }
}

.user-avatar-component {
  border-color: map-get(spotlab.$colors, 'gray');
}

.in-progress .user-avatar-component {
  border-color: map-get(spotlab.$colors, 'orange');
  span {
    color: map-get(spotlab.$colors, 'orange');
  }
}
.analyzed .user-avatar-component {
  border-color: map-get(spotlab.$colors, 'green');
  span {
    color: map-get(spotlab.$colors, 'green');
  }
}

div.roi-overlay {
  width: auto;
  height: auto;
  // background-color: rgba(255, 95, 95, 0.24);
  position: relative;
  box-sizing: border-box;
  border: 1px solid white;
  // pointer-events: none;

  &.roi-overlay-ai {
    i {
      position: absolute;
      top: 2px;
      right: 2px;
      font-size: 8px;
    }
  }

  &:hover {
    background-color: transparent !important;
    border: 1px dotted rgb(255, 255, 255);
  }

  &.selected {
    border: 2px solid red;
    border-style: double;
  }
}

i.roi-overlay {
  &:hover {
    opacity: 0.4;
  }
}

.list-group {
  &--no-borders {
    li {
      border: none;
    }
  }
}
