@use "spotlab" as *;
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat-core();

// Generated using http://mcg.mbitson.com/#!?mcgpalette0=%230099bb
$spotlab-main: (
  50: #e0f3f7,
  100: #b3e0eb,
  200: #80ccdd,
  300: #4db8cf,
  400: #26a8c5,
  500: #0099bb,
  600: #0091b5,
  700: #0086ac,
  800: #007ca4,
  900: #006b96,
  A100: #c1eaff,
  A200: #8ed9ff,
  A400: #5bc8ff,
  A500: #0099bb,
  A700: #41bfff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A500: #000000,
    A700: #000000,
  ),
);

$spotlab-accent: (
  50: #fef2e4,
  100: #fddfbb,
  200: #fbc98e,
  300: #f9b361,
  400: #f8a33f,
  500: #f7931d,
  600: #f68b1a,
  700: #f58015,
  800: #f37611,
  900: #f1640a,
  A100: #ffffff,
  A200: #ffefe7,
  A400: #ffceb4,
  A700: #ffbd9b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$spotlab-warn: (
  50: #f7e4e6,
  100: #ecbcc0,
  200: #df8f96,
  300: #d2626c,
  400: #c8404d,
  500: #be1e2d,
  600: #b81a28,
  700: #af1622,
  800: #a7121c,
  900: #990a11,
  A100: #ffc7c9,
  A200: #ff9497,
  A400: #ff6165,
  A700: #ff474d,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

// Define the default theme (same as the example above).
$telespot-primary: mat-palette($spotlab-main);
$telespot-accent: mat-palette($spotlab-accent);
$telespot-warn: mat-palette($spotlab-warn);
$telespot-theme: mat-light-theme(
  $telespot-primary,
  $telespot-primary,
  $telespot-warn
);
$telespot-progress-bar-theme: mat-light-theme(
  $telespot-primary,
  $telespot-accent,
  $telespot-warn
);

// Include the default theme styles.
@include angular-material-color($telespot-theme);
@include mat-checkbox-color($telespot-theme);
@include mat-progress-bar-color($telespot-theme);

mat-progress-bar {
  @include mat-progress-bar-color($telespot-progress-bar-theme);
}

.mat-row {
  cursor: pointer;
}

.color-accent {
  color: map-get($spotlab-accent, 500);
}
