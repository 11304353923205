@use 'spotlab' as *;
mat-label {
  &:not(.no-padding) {
    padding-bottom: 20px;
  }
  .mat-icon {
    &.right-primary-icon {
      font-size: 32px;
      color: map-get($colors, main);
    }
  }

  &.padding-h-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
}
