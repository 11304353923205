@use 'animations' as *;
@use 'spotlab';

[class^='ri-'] {
  font-size: spotlab.$icon-size;
  &.disabled {
    // REVIEW
    color: map-get(spotlab.$colors, 'gray');
  }
}

i.spin {
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  float: left;

  &.pulse {
    -webkit-animation: pulse 2s linear infinite;
    -moz-animation: pulse 2s linear infinite;
    animation: spin 2s linear infinite, pulse 2s linear infinite;
  }
}
